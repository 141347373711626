/* @import '../../less/antd-custom.less'; */

html {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

/* * {cursor: pointer;} */

body,
#root {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  /* overflow-x: hidden; */
}